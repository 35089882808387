<script setup lang="ts">
  const props = defineProps<{
    imgStr: string,
  }>()
  defineEmits<{
    (e: 'click'): void
  }>()
  const heightClass = 'h-[20px]'
  const widthClass = 'w-[20px]'
</script>
<template>
  <button
    @click="$emit('click')"
  >
    <div
      class="
        bg-gray-800
        flex
        items-center
        w-20
      "
    >
      <div
        class="
          inline-block
          p-[2px]
          w-4/12
        "
      >
        <common-avatar
          :height-class="heightClass"
          :img-str="props.imgStr"
          round-size="none"
          :width-class="widthClass"
        >
        </common-avatar>
      </div>
      <span
        class="
          font-semibold
          text-center
          text-xs
          text-white
          whitespace-nowrap
          w-8/12
        "
      >
        会員切替
      </span>
    </div>
  </button>
</template>
