<script setup lang="ts">
  import { useAppStatusGuardStore } from '~/store/pinia/app-status-guard'

  const props = defineProps<{
    pageTitle: string,
    topPageFlg: boolean,
  }>()
  const emits = defineEmits<{
    (e: 'historyBack'): void,
    (e: 'logout'): void,
    (e: 'switchStudent'): void,
  }>()
</script>
<template>
  <div
    class="
      common-background-leaf
      flex
      h-11
      items-center
      justify-between
    "
  >
    <!-- 左部分 -->
    <div
      :class="[
        props.topPageFlg? 'w-32': 'w-11'
      ]"
    >
      <div
        v-show="!props.topPageFlg"
        class="
          flex
          items-center
        "
      >
        <span
          class="
            material-icons
            md-36
            md-light
          "
          @click="emits('historyBack')"
        >
          chevron_left
        </span>
      </div>
    </div>
    <!-- ページ名 -->
    <div
      class="
        text-center
      "
    >
      <span
        class="
          font-semibold
          text-lg
          text-white
          truncate
        "
      >
        {{ pageTitle }}
      </span>
    </div>
    <!-- 右部分 -->
    <div
      class="
      "
    >
      <div
        class="
          flex
          items-center
          justify-end
        "
      >
        <div
          class="
            text-right
          "
        >
          <common-header-switch-student-btn
            v-if="props.topPageFlg"
            :img-str="useAppStatusGuardStore().getAvatar()"
            @click="emits('switchStudent')"
            class="
              py-1
            "
          >
          </common-header-switch-student-btn>
        </div>
        <!-- ログアウトボタン -->
        <div
          class="
            mx-0
          "
        >
          <div
            class="
              logout-text
              text-center
              text-white
              whitespace-nowrap
            "
            @click="emits('logout')"
          >
            <div
              class="
                logout-icon
                material-icons
              "
            >
              logout
            </div>
            <div
              class="
                logout-text
              "
            >
              ログアウト
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .logout-text {
    font-size: clamp(10px, 1vw, 40px);
    transform: scale(0.9);
  }
  .logout-icon {
    font-size: clamp(18px, 1vw, 36px);
  }
</style>
